import * as React from 'react';
import { connect } from 'react-redux';
import { Button } from "reactstrap";
import { ApplicationState } from '../../store/index';
import * as DialogStore from '../../store/DialogStore';
import { httpService } from '../../index';
import { Competition } from '../../models/competition/Competition';
import { Helpers } from '../../utility/Helpers';

type CompActionsProps = {
	compId: string;
	comp?: Competition;
	compChanged: Function;
}

const INVOICE_NUMBER = 'invoice-number';
const INVOICE_PDF = 'invoice-pdf';
const INVOICE_CSV = 'invoice-csv';
const INVOICE_FEE_PDF = 'invoice-ree-pdf';
const INVOICE_FEE_CSV = 'invoice-ree-csv';

const SEND_SUCCESS_ENTRY = 0;
const SEND_START_NUMBERS = 1;
const SEND_INVOICES_EXTRAS = 2;
const SEND_INVOICES = 3;
const SEND_INVOICES_AND_FEES = 4;
const SEND_FEE_INVOICES = 5;
const SEND_EXTRAS = 6;

class CompActions extends React.Component<CompActionsProps & typeof DialogStore.actionCreators, { loading: string }> {

	constructor(props: any) {
		super(props);

		this.state = {
			loading: ''
		}
	}

	async generateDocument(type: string) {

		let action: any;

		switch (type) {
			case INVOICE_NUMBER: {
				action = async () => { return await httpService.generateInvoiceNumbers(this.props.compId) }
				break;
			}
			case INVOICE_PDF: {
				action = async () => { return await httpService.generateInvoicePDF(this.props.compId) }
				break;
			}
			case INVOICE_CSV: {
				action = async () => { return await httpService.generateInvoiceCSV(this.props.compId) }
				break;
			}
			case INVOICE_FEE_PDF: {
				action = async () => { return await httpService.generateInvoiceFeePDF(this.props.compId) }
				break;
			}
			case INVOICE_FEE_CSV: {
				action = async () => { return await httpService.generateInvoiceFeeCSV(this.props.compId) }
				break;
			}
		}

		try {
			this.setState({ loading: type });

			let res = await action();

			if (type !== INVOICE_NUMBER) {
				//window.open(res, '_blank');
			} else {
				this.props.compChanged(res.id);
			}

			this.setState({ loading: '' });
		} catch (err) {
			this.setState({ loading: '' });
			alert(err.message ? err.message : err.title);
		}
	}

	async sendEmail(type: Number) {
		let action: any;
		let text: string = '';

		switch (type) {
			case SEND_SUCCESS_ENTRY: {
				action = async () => { return await httpService.sendSuccesEntry(this.props.compId) }
				text = 'success entry'
				break;
			}
			case SEND_START_NUMBERS: {
				action = async () => { return await httpService.sendStartNumbers(this.props.compId) };
				text = 'start numbers'
				break;
			}
			case SEND_INVOICES_EXTRAS: {
				action = async () => { return await httpService.sendInvoicesAndExtras(this.props.compId) };
				text = 'invoices and extras'
				break;
			}
			case SEND_INVOICES: {
				action = async () => { return await httpService.sendInvoices(this.props.compId) };
				text = 'invoices'
				break;
			}
			case SEND_INVOICES_AND_FEES: {
				action = async () => { return await httpService.sendInvoicesAndFees(this.props.compId) };
				text = 'invoices and fees'
				break;
			}
			case SEND_FEE_INVOICES: {
				action = async () => { return await httpService.sendFeeInvoices(this.props.compId) };
				text = 'fee invoices'
				break;
			}
			case SEND_EXTRAS: {
				action = async () => { return await httpService.sendExtras(this.props.compId) };
				text = 'extras'
				break;
			}
		}

		this.props.showDialog(`Are you sure to send ${text} e-mails ?`, DialogStore.DialogType.Confirm, async (confirm: boolean) => {
			if (confirm) {
				try {
					this.props.increment();
					let res = await action();
					this.props.compChanged(res.id);
					this.props.decrement();

				} catch (err) {
					this.props.decrement();
					alert(err.message);
				}
            }
		})
    }

	render() {
		return (
			<div>
				<h5>Invoice generation</h5>
				<br />
				<div>
					Last generated invoice numbers: {(this.props.comp && this.props.comp.invoiceNumbersGeneratedTimestamp) ? Helpers.getLocalDateTimeString(this.props.comp.invoiceNumbersGeneratedTimestamp) : '/'}
					<br/>
					<Button	color="info"
						style={{ color: "white" }}
						onClick={() => {
							this.generateDocument(INVOICE_NUMBER)
						}}
						disabled={this.state.loading === INVOICE_NUMBER}
					>Generate Invoice Numbers
						{this.state.loading === INVOICE_NUMBER &&
							<i className="fa fa-refresh fa-spin"
								style={{ marginRight: "5px", marginLeft: "10px" }} />
						}
					</Button>
					<br /><br />
					<Button color="info"
						style={{ color: "white" }}
						onClick={() => {
							this.generateDocument(INVOICE_PDF)
						}}
						disabled={this.state.loading === INVOICE_PDF}
					>Generate Invoice PDFs
						{this.state.loading === INVOICE_PDF &&
							<i className="fa fa-refresh fa-spin"
								style={{ marginRight: "5px", marginLeft: "10px" }} />
						}
					</Button>
					<br /><br />
					<Button color="info"
						style={{ color: "white" }}
						onClick={() => {
							this.generateDocument(INVOICE_CSV)
						}}
						disabled={this.state.loading === INVOICE_CSV}
					>Generate Invoice CSV
						{this.state.loading === INVOICE_CSV &&
							<i className="fa fa-refresh fa-spin"
								style={{ marginRight: "5px", marginLeft: "10px" }} />
						}
					</Button>
					<br /><br />
					<Button color="info"
						style={{ color: "white" }}
						onClick={() => {
							this.generateDocument(INVOICE_FEE_PDF)
						}}
						disabled={this.state.loading === INVOICE_FEE_PDF}
					>Generate Invoice Fee PDF
						{this.state.loading === INVOICE_FEE_PDF &&
							<i className="fa fa-refresh fa-spin"
								style={{ marginRight: "5px", marginLeft: "10px" }} />
						}
					</Button>
					<br /><br />
					<Button color="info"
						style={{ color: "white" }}
						onClick={() => {
							this.generateDocument(INVOICE_FEE_CSV)
						}}
						disabled={this.state.loading === INVOICE_FEE_CSV}
					>Generate Invoice Fee CSV
						{this.state.loading === INVOICE_FEE_CSV &&
							<i className="fa fa-refresh fa-spin"
								style={{ marginRight: "5px", marginLeft: "10px" }} />
						}
					</Button>
				</div>
				<br /><br />
				<h5>Email sending</h5>
				<br />
				{this.props.comp && <div>
					<div>Successfull Entry sent: {this.props.comp.successfullEntrySent ? Helpers.getLocalDateTimeString(this.props.comp.successfullEntrySent) : '/'}</div>
					<Button color="info"
						style={{ color: "white" }}
						disabled={(this.props.comp.successfullEntrySent) ? true : false}
						onClick={() => {
							this.sendEmail(SEND_SUCCESS_ENTRY)
						}}
					>Send Successfull Entry</Button>

					<br /><br />

					<div>Start Numbers sent: {this.props.comp.startNumbersAndScheduleSent ? Helpers.getLocalDateTimeString(this.props.comp.startNumbersAndScheduleSent) : '/'}</div>
					<Button color="info"
						style={{ color: "white" }}
						disabled={(this.props.comp.startNumbersAndScheduleSent) ? true : false}
						onClick={() => {
							this.sendEmail(SEND_START_NUMBERS)
						}}
					>Send Start Numbers</Button>

					<br /><br />

					<div>Invoices and/or Extras sent: {this.props.comp.invoicesAndExtraSent ? Helpers.getLocalDateTimeString(this.props.comp.invoicesAndExtraSent) : '/'}</div>
					<Button color="info"
						style={{ color: "white" }}
						disabled={(this.props.comp.invoicesAndExtraSent) ? true : false}
						onClick={() => {
							this.sendEmail(SEND_INVOICES_EXTRAS)
						}}
					>Send Invoices and Extras</Button>
					<br />
					<br />
					<Button color="info"
						style={{ color: "white" }}
						disabled={(this.props.comp.invoicesAndExtraSent) ? true : false}
						onClick={() => {
							this.sendEmail(SEND_INVOICES)
						}}
					>Send Invoices</Button>
					<br />
					<br />
					<Button color="info"
						style={{ color: "white" }}
						disabled={(this.props.comp.invoicesAndExtraSent) ? true : false}
						onClick={() => {
							this.sendEmail(SEND_INVOICES_AND_FEES)
						}}
					>Send Invoices and Fee Invoices</Button>
					<br />
					<br />
					<Button color="info"
						style={{ color: "white" }}
						disabled={(this.props.comp.invoicesAndExtraSent) ? true : false}
						onClick={() => {
							this.sendEmail(SEND_FEE_INVOICES)
						}}
					>Send Fee Invoices</Button>
					<br />
					<br />
					<Button color="info"
						style={{ color: "white" }}
						disabled={(this.props.comp.invoicesAndExtraSent) ? true : false}
						onClick={() => {
							this.sendEmail(SEND_EXTRAS)
						}}
					>Send Only Extras</Button>
				</div>
				}
			</div>
		)
	}

	static defaultProps: CompActionsProps = {
		compId: '',
		compChanged: () => { }
	}
}

export default connect(
	(state: ApplicationState) => { return { dialog: state.dialog } },
	DialogStore.actionCreators
)(CompActions)
