import { IssuerType } from '../models/invoice/InvoiceEnums';
import * as ProfileStore from '../store/ProfileStore'

export interface User {
    email: string,
    password: string,
    rememberMe: boolean

}

export interface ChangePassword extends User {
    newPassword: string
}

class HttpService {

    baseUrl: string;

    constructor(private store: any, private history: any) {
        this.baseUrl = 'api/';
    }

    private getOptions() {
        return {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        };
    }

    private getFileOptions() {
        return {
            method: 'GET',
            headers: {'Content-Type': 'application/pdf'}
        };
    }

    private getCustomFileOptions(type: string) {
        return {
            method: 'GET',
            headers: { 'Content-Type': type }
        };
    }

    private getImageOptions() {
        return {
            method: 'GET',
            headers: {'Content-Type': 'image/png'}
        };
    }

    private postOptions(data: any) {
        return {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        };
    }

    private postFileOptions(data: any) {
        return {
            method: 'POST',
            body: (data),
          
        };
    }

    private postDogOptions() {
        return {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},

        };
    }

    private deleteOptions() {
        return {
            method: 'DELETE',
            headers: {'Content-Type': 'application/json'}
        };
    }


    private deleteBodyOptions(data: any) {
        return {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        };
    }

    async login(data: User) {
        return fetch(`${this.baseUrl}profile/login`, this.postOptions(data)).then(this.handleResponse);
    }

    async logout() {
        return fetch(`${this.baseUrl}logout`, this.getOptions()).then(this.handleResponse);
    }

    async passwordChange(data: ChangePassword) {
        return fetch(`${this.baseUrl}profile/passwordchange`, this.postOptions(data)).then(this.handleResponse);
    }

    async getVersion() {
        return fetch(`${this.baseUrl}version`, this.getOptions()).then(this.handleResponse);
    }

    async getRegisteredUsers(params: any = {}) {
        let queryString = `${this.baseUrl}registered-user?`;
        const defaults = {
            page: null,
            count: null,
            username: null,
            usertype: null
           
        };
        params = { ...defaults, ...params };
        for (const key in (params)) {
            if (params[key] === undefined || params[key] === null || params[key] === '') {
                delete params[key];
            }
            
        }
        const queryParams = new URLSearchParams(params).toString();
        queryString += queryParams;
        return fetch(queryString, this.getOptions()).then(this.handleResponse);
    }

    async getFilteredRegisteredUsers(username: string) {
        return fetch(`${this.baseUrl}registered-user?username=${username || ""}`, this.getOptions()).then(this.handleResponse);

    }

    async registeredUserReorder(id: string, pos?: number) {
        let url = pos != undefined ? `api/registered-user/reorder/${id}?position=${pos}` : `api/registered-user/reorder/${id}`;
        return fetch(url, this.getOptions()).then(this.handleResponse);
    }

    async getRegisteredUser(id: string) {
        return fetch(`${this.baseUrl}registered-user/${id}`, this.getOptions()).then(this.handleResponse);
    }

    async registeredUserAddOrUpdate(data: any) {
        return fetch(`${this.baseUrl}registered-user`, this.postOptions(data)).then(this.handleResponse);
    }

    async registeredUserDelete(id: string) {
        return fetch(`${this.baseUrl}registered-user/${id}`, this.deleteOptions()).then(this.handleResponse);
    }
    async getUserRoles() {
        return fetch(`${this.baseUrl}registered-user/list-roles`, this.getOptions()).then(this.handleResponse);
    }
    async getUserHistory(id: string) {
        return fetch(`${this.baseUrl}registered-user/history/${id}`, this.getOptions()).then(this.handleResponse);
    }


    async getCountries() {
        return await fetch(`${this.baseUrl}country`, this.getOptions()).then(this.handleResponse);
    }

    async countryReorder(id: string, pos?: number) {
        let url = pos != undefined ? `api/country/reorder/${id}?position=${pos}` : `api/country/reorder/${id}`;
        return fetch(url, this.getOptions()).then(this.handleResponse);
    }


    async getCountry(id: string) {
        return fetch(`${this.baseUrl}country/${id}`, this.getOptions()).then(this.handleResponse);
    }

    async countryAddOrUpdate(data: any) {
        return await fetch(`${this.baseUrl}country`, this.postOptions(data)).then(this.handleResponse);
    }

    async countryDelete(id: string) {
        return await fetch(`${this.baseUrl}country/${id}`, this.deleteOptions()).then(this.handleResponse);
    }

    async getStripeAccounts() {
        return await fetch(`${this.baseUrl}stripe-account`, this.getOptions()).then(this.handleResponse);
    }

    async getStripeAccount(id: string) {
        return fetch(`${this.baseUrl}stripe-account/${id}`, this.getOptions()).then(this.handleResponse);
    }

    async stripeAccountAddOrUpdate(data: any) {
        return await fetch(`${this.baseUrl}stripe-account`, this.postOptions(data)).then(this.handleResponse);
    }

    async stripeAccountDelete(id: string) {
        return await fetch(`${this.baseUrl}stripe-account/${id}`, this.deleteOptions()).then(this.handleResponse);
    }

    async getCompetitions(page: number = 0, count: number = 25) {
        return fetch(`${this.baseUrl}competition?page=${page}&count=${count}`, this.getOptions()).then(this.handleResponse);
    }

    async competitionReorder(id: string, pos?: number) {
        let url = pos != undefined ? `api/competition/reorder/${id}?position=${pos}` : `api/competition/reorder/${id}`;
        return fetch(url, this.getOptions()).then(this.handleResponse);
    }

    async getCompetition(id: string) {
        return fetch(`${this.baseUrl}competition/${id}`, this.getOptions()).then(this.handleResponse);
    }

    async competitionAddOrUpdate(data: any) {
        return fetch(`${this.baseUrl}competition`, this.postOptions(data)).then(this.handleResponse);
    }

    async competitionDelete(id: string) {
        return fetch(`${this.baseUrl}competition/${id}`, this.deleteOptions()).then(this.handleResponse);
    }

    async competitionFileUpload(file: File, id: string) {
        let fd = new FormData();
        fd.append('file', file);
        return fetch(`${this.baseUrl}competition-attachment/file-upload/${id}`, this.postFileOptions(fd)).then(this.handleResponse);
    }

    async competitionFileDelete(id: string) {
        return fetch(`${this.baseUrl}competition-attachment/file-delete/${id}`, this. deleteOptions()).then(this.handleResponse);
    }

    async competitionAttachment(id: string) {
        return fetch(`${this.baseUrl}competition-attachment/${id}`, this.getOptions()).then(this.handleResponse);
    }

    async competitionAttachmentAddOrUpdate(data: any) {        
        return fetch(`${this.baseUrl}competition-attachment`, this.postOptions(data)).then(this.handleResponse);
    }

    async competitionAttachmentDelete(id: string) {
        return fetch(`${this.baseUrl}competition-attachment/${id}`, this.deleteOptions()).then(this.handleResponse);
    }

    async getEventOwners(id: string) {
        return fetch(`${this.baseUrl}competition/event-owners/${id}`, this.getOptions()).then(this.handleResponse);
    }
    async eventOwnersAdd(data: any) {
        return fetch(`${this.baseUrl}competition/add-event-owner`, this.postOptions(data)).then(this.handleResponse);
    }
    async eventOwnerDelete(data: any) {
        return fetch(`${this.baseUrl}competition/remove-event-owner`, this.deleteBodyOptions(data)).then(this.handleResponse);
    }
    

    async competitionDateAddOrUpdate(data: any) {
        return fetch(`${this.baseUrl}competition-date`, this.postOptions(data)).then(this.handleResponse);
    }

    async competitionDateDelete(id: string) {
        return fetch(`${this.baseUrl}competition-date/${id}`, this.deleteOptions()).then(this.handleResponse);
    }

    async competitionDateIntroUpload(file: File, competitionDateId: string) {
        let fd = new FormData();
        fd.append('file', file);
        return fetch(`${this.baseUrl}competition-date/intro-pages-upload/${competitionDateId}`, this.postFileOptions(fd)).then(this.handleResponse);
    }

    async competitionDateClosingUpload(file: File, competitionDateId: string) {
        let fd = new FormData();
        fd.append('file', file);
        return fetch(`${this.baseUrl}competition-date/closing-pages-upload/${competitionDateId}`, this.postFileOptions(fd)).then(this.handleResponse);
    }

    async competitionDateIntroDelete(competitionDateId: string) {
        return fetch(`${this.baseUrl}competition-date/intro-pages-delete/${competitionDateId}`, this.deleteOptions()).then(this.handleResponse);
    }

    async competitionDateClosingDelete(competitionDateId: string) {
        return fetch(`${this.baseUrl}competition-date/closing-pages-delete/${competitionDateId}`, this.deleteOptions()).then(this.handleResponse);
    }

    async competitionDateBIS(competitionDateBisId: string) {
        return fetch(`${this.baseUrl}competition-date-bis/${competitionDateBisId}`, this.getOptions()).then(this.handleResponse);
    }

    async competitionDateBISDelete(competitionDateBisId: string) {
        return fetch(`${this.baseUrl}competition-date-bis/${competitionDateBisId}`, this.deleteOptions()).then(this.handleResponse);
    }

    async competitionDateBISAddOrUpdate(data: any) {
        return fetch(`${this.baseUrl}competition-date-bis`, this.postOptions(data)).then(this.handleResponse);
    }

    async customBISList(competitionDateId: string) {
        return fetch(`${this.baseUrl}competition-date-bis/list-for-competition-date/${competitionDateId}`, this.getOptions()).then(this.handleResponse);
    }

    async bisQrCodes(compDateBisId: string) {
        return await fetch(`${this.baseUrl}generate/bis-qr-codes/${compDateBisId}`, this.getFileOptions()).then(this.handlePdfResponse);
    }


    async competitionLogoUpload(file: File, compId: string) {
        let fd = new FormData();
        fd.append('file', file);
        return fetch(`${this.baseUrl}competition/logo-upload/${compId}`, this.postFileOptions(fd)).then(this.handleResponse);
    }

    async competitionLogoDelete(compId: string) {
        return fetch(`${this.baseUrl}competition/logo-delete/${compId}`, this.deleteOptions()).then(this.handleResponse);
    }

    async couponAddOrUpdate(data: any) {
        return fetch(`${this.baseUrl}coupon`, this.postOptions(data)).then(this.handleResponse);
    }

    async couponDelete(id: string) {
        return fetch(`${this.baseUrl}coupon/${id}`, this.deleteOptions()).then(this.handleResponse);
    }

    async getCoupon(id: string) {
        return fetch(`${this.baseUrl}coupon/${id}`, this.getOptions()).then(this.handleResponse);
    }

    async couponImport(file: File, id: string) {
        let fd = new FormData();
        fd.append('file', file);
        return fetch(`${this.baseUrl}coupon/import/${id}`, this.postFileOptions(fd)).then(this.handleResponse);
    }

    async couponExport(id: string) {
        return fetch(`${this.baseUrl}coupon/export/${id}`, this.getCustomFileOptions('text/plain')).then(this.handlePdfResponse);
    }

    async getCustomDiscount(id: string) {
        return fetch(`${this.baseUrl}custom-discount/${id}`, this.getOptions()).then(this.handleResponse);
    }

    async customDiscountAddOrUpdate(data: any) {
        return fetch(`${this.baseUrl}custom-discount`, this.postOptions(data)).then(this.handleResponse);
    }

    async customDiscountDelete(id: string) {
        return fetch(`${this.baseUrl}custom-discount/${id}`, this.deleteOptions()).then(this.handleResponse);
    }

    async getCustomDiscountDogReg(competitionDateId: string) {
        return fetch(`${this.baseUrl}custom-discount/dog-reg/${competitionDateId}`, this.getOptions()).then(this.handleResponse);
    }

    async getCustomDiscountCoupleGroupReg(competitionDateId: string) {
        return fetch(`${this.baseUrl}custom-discount/couple-group-reg/${competitionDateId}`, this.getOptions()).then(this.handleResponse);
    }

    async getCustomDiscountJuniorHandlingReg(competitionDateId: string) {
        return fetch(`${this.baseUrl}custom-discount/juniorhandling-reg/${competitionDateId}`, this.getOptions()).then(this.handleResponse);
    }

    async competitionSponsorFileUpload(file: File, id: string) {
        let fd = new FormData();
        fd.append('file', file);
        return fetch(`${this.baseUrl}sponsor/image-upload/${id}`, this.postFileOptions(fd)).then(this.handleResponse);
    }

    async competitionSponsorFileDelete(id: string) {
        return fetch(`${this.baseUrl}sponsor/image-delete/${id}`, this.deleteOptions()).then(this.handleResponse);
    }

    async competitionSponsor(id: string) {
        return fetch(`${this.baseUrl}sponsor/${id}`, this.getOptions()).then(this.handleResponse);
    }

    async competitionSponsorAddOrUpdate(data: any) {
        return fetch(`${this.baseUrl}sponsor`, this.postOptions(data)).then(this.handleResponse);
    }

    async competitionSponsorDelete(id: string) {
        return fetch(`${this.baseUrl}sponsor/${id}`, this.deleteOptions()).then(this.handleResponse);
    }

    async competitionDateImageFileUpload(file: File, id: string) {
        let fd = new FormData();
        fd.append('file', file);
        return fetch(`${this.baseUrl}competition-date-image/image-upload/${id}`, this.postFileOptions(fd)).then(this.handleResponse);
    }

    async competitionDateImageFileDelete(id: string) {
        return fetch(`${this.baseUrl}competition-date-image/image-delete/${id}`, this.deleteOptions()).then(this.handleResponse);
    }

    async competitionDateImage(id: string) {
        return fetch(`${this.baseUrl}competition-date-image/${id}`, this.getOptions()).then(this.handleResponse);
    }

    async getCompetitionDateImage(name: string) {
        return fetch(`${this.baseUrl}competition-date-image/image/${name}`, this.getOptions()).then(this.handleImageResponse);
    }

   async competitionDateImageAddOrUpdate(data: any) {
        return fetch(`${this.baseUrl}competition-date-image`, this.postOptions(data)).then(this.handleResponse);
    }

    async competitionDateImageDelete(id: string) {
        return fetch(`${this.baseUrl}competition-date-image/${id}`, this.deleteOptions()).then(this.handleResponse);
    }

    async getDog(id: string) {
        return fetch(`${this.baseUrl}dog/${id}`, this.getOptions()).then(this.handleResponse);
    }

    async getDogRegistrations(id: string) {
        return fetch(`${this.baseUrl}dog/registrations/${id}`, this.getOptions()).then(this.handleResponse);
    }
    async getDogHistory(id: string) {
        return await fetch(`${this.baseUrl}dog/history/${id}`, this.getOptions()).then(this.handleResponse);
    }


    async invoiceIssuers(query: string, type: IssuerType) {
        return fetch(`${this.baseUrl}invoice-issuer?q=${query}&type=${type}`, this.getOptions()).then(this.handleResponse);
    }
    async getInvoiceIssuer(id: any) {
        return fetch(`${this.baseUrl}invoice-issuer/${id}`, this.getOptions()).then(this.handleResponse);
    }
    async addOrUpdateInvoiceIssuer(data: any) {
        return await fetch(`${this.baseUrl}invoice-issuer`, this.postOptions(data)).then(this.handleResponse);
    }

    async invoiceInfoList(type: IssuerType) {
        return fetch(`${this.baseUrl}invoice-info?type=${type}`, this.getOptions()).then(this.handleResponse);
    }
    async getInvoiceStamp(name: any) {
        return await fetch(`${this.baseUrl}invoice-issuer/stamp/${name}`, this.getFileOptions()).then(this.handleResponse);
    }
    async stampPathImageUpload(stamp: File, id: string) {
        let fd = new FormData();
        fd.append('stamp', stamp);
        return await fetch(`${this.baseUrl}invoice-issuer/upload-stamp/${id}`, this.postFileOptions(fd)).then(this.handleResponse);
    }
    async deleteInvoiceStamp(id: any) {
        return fetch(`${this.baseUrl}invoice-issuer/delete-stamp/${id}`, this.deleteOptions()).then(this.handleResponse);
    }

    async getUnconfirmedDogs(params: any = {}) {
        let queryString = `${this.baseUrl}dog/unconfirmed?`;
        const defaults = {
            page: null,
            name: null,
            count: null,
            profile: null

        };
        params = { ...defaults, ...params };
        for (const key in (params)) {
            if (params[key] === undefined || params[key] === null || params[key] === '') {
                delete params[key];
            }

        }
        const queryParams = new URLSearchParams(params).toString();
        queryString += queryParams;
        return fetch(queryString, this.getOptions()).then(this.handleResponse);
    }

    async getConfirmedDogs(params: any = {}) {
        let queryString = `${this.baseUrl}dog/confirmed?`;
        const defaults = {
            page: null,
            name: null,
            count: null,
            profile: null

        };
        params = { ...defaults, ...params };
        for (const key in (params)) {
            if (params[key] === undefined || params[key] === null || params[key] === '') {
                delete params[key];
            }

        }
        const queryParams = new URLSearchParams(params).toString();
        queryString += queryParams;
        return fetch(queryString, this.getOptions()).then(this.handleResponse);
    }

    async getRejectedDogs(params: any = {}) {
        let queryString = `${this.baseUrl}dog/rejected?`;
        const defaults = {
            page: null,
            name: null,
            count: null,
            profile: null

        };
        params = { ...defaults, ...params };
        for (const key in (params)) {
            if (params[key] === undefined || params[key] === null || params[key] === '') {
                delete params[key];
            }

        }
        const queryParams = new URLSearchParams(params).toString();
        queryString += queryParams;
        return fetch(queryString, this.getOptions()).then(this.handleResponse);
    }

    async dogConfirm(id: string) {
        return await fetch(`${this.baseUrl}dog/confirm/${id}`, this.postDogOptions()).then(this.handleResponse);
    }

    async dogReject(id: string) {
        return await fetch(`${this.baseUrl}dog/reject/${id}`, this.postDogOptions()).then(this.handleResponse);
    }

    async dogReorder(id: string, pos?: number) {
        let url = pos != undefined ? `api/dog/reorder/${id}?position=${pos}` : `api/dog/reorder/${id}`;
        return fetch(url, this.getOptions()).then(this.handleResponse);
    }

    async dogAddOrUpdate(data: any) {
        return await fetch(`${this.baseUrl}dog`, this.postOptions(data)).then(this.handleResponse);
    }

    async dogDelete(id: string) {
        return fetch(`${this.baseUrl}dog/${id}`, this.deleteOptions()).then(this.handleResponse);
    }

    async dogAttachmentUpload(file: File, id: string, type: number) {
        let fd = new FormData();
        fd.append('file', file);
        return fetch(`${this.baseUrl}dog/attachment-upload/${type}/${id}`, this.postFileOptions(fd)).then(this.handleResponse);
    }

    async dogAttachmentDelete(id: string) {
        return fetch(`${this.baseUrl}dog/attachment-delete/${id}`, this.deleteOptions()).then(this.handleResponse);
    }

    async dogImageUpload(file: File, id: string) {
        let fd = new FormData();
        fd.append('file', file);
        return fetch(`${this.baseUrl}dog/image-upload/${id}`, this.postFileOptions(fd)).then(this.handleResponse);
    }

    async getProfileDogs(id: string) {
        return fetch(`${this.baseUrl}dog/list-for-user/${id}`, this.getOptions()).then(this.handleResponse);
    }

    async dogImageDelete(id: string) {
        return fetch(`${this.baseUrl}dog/image-delete/${id}`, this.deleteOptions()).then(this.handleResponse);
    }

    async getDogCertificate(id: string) {
        return fetch(`${this.baseUrl}generate/certificate/${id}`, this.getFileOptions()).then(this.handlePdfResponse);
    }

    async getDogAppreciation(id: string) {
        return fetch(`${this.baseUrl}generate/appreciation/${id}`, this.getFileOptions()).then(this.handlePdfResponse);
    }


    async webBreeds() {
        return await fetch(`${this.baseUrl}breed/web-breeds`, this.getOptions()).then(this.handleResponse);
    }

    async catalogBreeds() {
        return await fetch(`${this.baseUrl}breed/catalog-breeds`, this.getOptions()).then(this.handleResponse);
    }

    async breeds() {
        return await fetch(`${this.baseUrl}breed`, this.getOptions()).then(this.handleResponse);
    }

    async getBreedsByFci(category: number) {
        return await fetch(`${this.baseUrl}breed/fci/${category}`, this.getOptions()).then(this.handleResponse);
    }

    async getBreed(id: string) {
        return await fetch(`${this.baseUrl}breed/${id}`, this.getOptions()).then(this.handleResponse);
    }

    async breedAddOrUpdate(data: any) {
        return await fetch(`${this.baseUrl}breed`, this.postOptions(data)).then(this.handleResponse);
    }

    async deleteBreed(id: string) {
        return await fetch(`${this.baseUrl}breed/${id}`, this.deleteOptions()).then(this.handleResponse);
    }

    async breedCategory() {
        return await fetch(`${this.baseUrl}breedcat`, this.getOptions()).then(this.handleResponse);
    }

    async getBreedCategory(id: string) {
        return await fetch(`${this.baseUrl}breedcat/${id}`, this.getOptions()).then(this.handleResponse);
    }

    async breedCategoryAddOrUpdate(data: any) {
        return await fetch(`${this.baseUrl}breedcat`, this.postOptions(data)).then(this.handleResponse);
    }

    async deleteBreedCategory(id: string) {
        return await fetch(`${this.baseUrl}breedcat/${id}`, this.deleteOptions()).then(this.handleResponse);
    }

    async pedigreeAbbreviations() {
        return await fetch(`${this.baseUrl}pedigree`, this.getOptions()).then(this.handleResponse);
    }

    async getPedigreeAbbreviation(id: string) {
        return await fetch(`${this.baseUrl}pedigree/${id}`, this.getOptions()).then(this.handleResponse);
    }

    async pedigreeAbbreviationAddOrUpdate(data: any) {
        return await fetch(`${this.baseUrl}pedigree`, this.postOptions(data)).then(this.handleResponse);
    }

    async deletePedigreeAbbreviation(id: string) {
        return await fetch(`${this.baseUrl}pedigree/${id}`, this.deleteOptions()).then(this.handleResponse);
    }
    async getCompetitionRegistrations(params: any = {}) {
        let queryString = `${this.baseUrl}registrations?`;
        const defaults = {
            page: null,
            count: null,
            competition: null,
            email: null,
            paymentStatus: null,
            catalogNumber: null,
            type: null,
            competitionDateId: null,
            dogName: null,
            dogOwner: null
        };
        params = { ...defaults, ...params };
        for (const key in (params)) {
            if (params[key] === undefined || params[key] === null || params[key] === '') {
                delete params[key];
            }
            
        }
        const queryParams = new URLSearchParams(params).toString();
        queryString += queryParams;
        return fetch(queryString, this.getOptions()).then(this.handleResponse);
    }

    async addOrUpdateRegistration(data: any) {
        return await fetch(`${this.baseUrl}registrations`, this.postOptions(data)).then(this.handleResponse);
    }

    async getCompetitionRegistrationDog(id: string) {
        return await fetch(`${this.baseUrl}dog-registration/${id}`, this.getOptions()).then(this.handleResponse);
    }
    async getCompetitionCoupleGroup(id: string) {
        return await fetch(`${this.baseUrl}couplegroup-registration/${id}`, this.getOptions()).then(this.handleResponse);
    }
    async getSpecialShowRegistration(id: string) {
        return await fetch(`${this.baseUrl}specialshow-registration/${id}`, this.getOptions()).then(this.handleResponse);
    }
    async getCompetitionJuniorSenior(id: string) {
        return await fetch(`${this.baseUrl}junior-handling-registration/${id}`, this.getOptions()).then(this.handleResponse);
    }

    async updateCompetitionRegistrations(data: any) {
        return await fetch(`${this.baseUrl}dog-registration`, this.postOptions(data)).then(this.handleResponse);
    }

    async updateCoupleGroupRegistrations(data: any) {
        return await fetch(`${this.baseUrl}couplegroup-registration`, this.postOptions(data)).then(this.handleResponse);
    }

    async updateSpecialShowRegistrations(data: any) {
        return await fetch(`${this.baseUrl}specialshow-registration`, this.postOptions(data)).then(this.handleResponse);
    }
    async updateJuniorSeniorRegistrations(data: any) {
        return await fetch(`${this.baseUrl}junior-handling-registration`, this.postOptions(data)).then(this.handleResponse);
    }

    async deleteCompetitionRegistrationDog(id: any) {
        return await fetch(`${this.baseUrl}dog-registration/${id}`, this.deleteOptions()).then(this.handleResponse);
    }

    async deleteCoupleGroupRegistration(id: any) {
        return await fetch(`${this.baseUrl}couplegroup-registration/${id}`, this.deleteOptions()).then(this.handleResponse);
    }

    async deleteSpecialShowRegistration(id: any) {
        return await fetch(`${this.baseUrl}specialshow-registration/${id}`, this.deleteOptions()).then(this.handleResponse);
    }
    async deleteJuniorSeniorRegistration(id: any) {
        return await fetch(`${this.baseUrl}junior-handling-registration/${id}`, this.deleteOptions()).then(this.handleResponse);
    }

    async registrationReorder(id: string, pos?: number) {
        let url = pos != undefined ? `api/registrations/reorder/${id}?position=${pos}` : `api/registrations/reorder/${id}`;
        return fetch(url, this.getOptions()).then(this.handleResponse);
    }

    async getCompetitionRegistration(id: string) {
        return await fetch(`${this.baseUrl}registrations/${id}`, this.getOptions()).then(this.handleResponse);
    }

    async competitionRegistrationAddOrUpdate(data: any) {
        return await fetch(`${this.baseUrl}registrations`, this.postOptions(data)).then(this.handleResponse);
    }

    async competitionRegistrationDelete(id: string) {
        return fetch(`${this.baseUrl}registrations/${id}`, this.deleteOptions()).then(this.handleResponse);
    }


    async competitionRegistrationConfirm(id: string) {
        return fetch(`${this.baseUrl}registrations/mark-as-succesfull/${id}`, this.getOptions()).then(this.handleResponse);
    }

    async getCompetitionDate(id: string) {
        return await fetch(`${this.baseUrl}competition-date/${id}`, this.getOptions()).then(this.handleResponse);
    }

    async getCompetitionClasses() {
        return await fetch(`${this.baseUrl}compclass`, this.getOptions()).then(this.handleResponse);
    }

    async getCompetitionClass(id: string) {
        return await fetch(`${this.baseUrl}compclass/${id}`, this.getOptions()).then(this.handleResponse);
    }

    async deleteCompetitionClass(id: string) {
        return await fetch(`${this.baseUrl}compclass/${id}`, this.deleteOptions()).then(this.handleResponse);
    }

    async addOrUpdateCompetitionClass(data: any) {
        return await fetch(`${this.baseUrl}compclass`, this.postOptions(data)).then(this.handleResponse);
    }

    async getRing(id: string) {
        return await fetch(`${this.baseUrl}competition-ring/${id}`, this.getOptions()).then(this.handleResponse);
    }
    async adddOrUpdateRing(data: any) {
        return await fetch(`${this.baseUrl}competition-ring`, this.postOptions(data)).then(this.handleResponse);
    }
    async deleteRing(id: string) {
        return await fetch(`${this.baseUrl}competition-ring/${id}`, this.deleteOptions()).then(this.handleResponse);
    }
    async getRingsForDate(id: string) {
        return await fetch(`${this.baseUrl}competition-ring/rings-for-show/${id}`, this.getOptions()).then(this.handleResponse);
    }

    async getPriceList(id: string) {
        return await fetch(`${this.baseUrl}price-list/${id}`, this.getOptions()).then(this.handleResponse);
    }

    async priceListAddOrUpdate(data: any) {
        return await fetch(`${this.baseUrl}price-list`, this.postOptions(data)).then(this.handleResponse);
    }

    async priceListDelete(id: string) {
        return await fetch(`${this.baseUrl}price-list/${id}`, this.deleteOptions()).then(this.handleResponse);
    }

    async getSpecialCompetition(id: string) {
        return await fetch(`${this.baseUrl}special-comp/${id}`, this.getOptions()).then(this.handleResponse);
    }

    async specialCompetitionAddOrUpdate(data: any) {
        return await fetch(`${this.baseUrl}special-comp`, this.postOptions(data)).then(this.handleResponse);
    }
    async specialCompetitionDelete(id: string) {
        return await fetch(`${this.baseUrl}special-comp/${id}`, this.deleteOptions()).then(this.handleResponse);
    }
    async getInvoiceInformationItems() {
        return await fetch(`${this.baseUrl}invoice-info/`, this.getOptions()).then(this.handleResponse);
    }

    async getInvoiceInformationDetails(id: any) {
        return await fetch(`${this.baseUrl}invoice-info/${id}`, this.getOptions()).then(this.handleResponse);
    }
    async addOrUpdateInvoiceInformation(data: any) {
        return await fetch(`${this.baseUrl}invoice-info`, this.postOptions(data)).then(this.handleResponse);
    }
    async getNotifications() {
        return await fetch(`${this.baseUrl}notification`, this.getOptions()).then(this.handleResponse);
    }
    async notificationDetails(id: string) {
        return await fetch(`${this.baseUrl}notification/${id}`, this.getOptions()).then(this.handleResponse);
    }
    async addOrUpdateNotification(data: any) {
        return await fetch(`${this.baseUrl}notification/`, this.postOptions(data)).then(this.handleResponse);
    }
    async notificationDelete(id: string) {
        return await fetch(`${this.baseUrl}notification/${id}`, this.deleteOptions()).then(this.handleResponse);
    }

    async generateEvaluatedSheets(id: string) {
        return await fetch(`${this.baseUrl}generate/evaluated-sheets/${id}`, this.getFileOptions()).then(this.handlePdfResponse);
    }

    async generateDocument(type: string, method?: any, data: any = {}) {
        if (method === "post") {
            return await fetch(`${this.baseUrl}${type}`, this.postOptions(data)).then(response => {
                if (!response.ok) {
                    // @ts-ignore
                    return
                }
                return "api/" + type;
            });
        } else {

            return await fetch(`${this.baseUrl}${type}`, this.getFileOptions()).then(response => {
                if (!response.ok) {
                    // @ts-ignore
                    return
                }
                return "api/" + type;
            });
        }

    }

    async exportRegistrations(compId: string, data: any = {}) {
        return await fetch(`${this.baseUrl}export-import/registrations/${compId}`, this.postOptions(data)).then(this.handlePdfResponse);
    }

    async exportResults(compId: string, data: any = {}) {
        return await fetch(`${this.baseUrl}export-import/results/${compId}`, this.postOptions(data)).then(this.handlePdfResponse);
    }

    async exportBISResults(compId: string, data: any = {}) {
        return await fetch(`${this.baseUrl}export-import/bis-results/${compId}`, this.postOptions(data)).then(this.handlePdfResponse);
    }

    async exportUsers() {
        return await fetch(`${this.baseUrl}export-import/users`, this.getOptions()).then(this.handlePdfResponse);
    }


    async generateInvoiceNumbers(compId: string) {
        return await fetch(`${this.baseUrl}invoice/generate-invoice-numbers/${compId}`, this.getOptions()).then(this.handleResponse);
    }

    async generateQrCodes(compId: string) {
        return await fetch(`${this.baseUrl}generate/qr-codes/${compId}`, this.getFileOptions()).then(this.handlePdfResponse);
    }
    async generateCatalog(compId: string) {
        return await fetch(`${this.baseUrl}generate/catalog/${compId}`, this.getFileOptions()).then(this.handlePdfResponse);
    }
    async generateSchedule(compId: string) {
        return await fetch(`${this.baseUrl}generate/schedule/${compId}`, this.getFileOptions()).then(this.handlePdfResponse);
    }
    async generateJudgeReport(compId: string) {
        return await fetch(`${this.baseUrl}generate/judge-report/${compId}`, this.getFileOptions()).then(this.handlePdfResponse);
    }

    async generateInvoicePDF(compId: string) {
        return await fetch(`${this.baseUrl}invoice/generate-invoice-pdf/${compId}`, this.getFileOptions()).then(this.handlePdfResponse);
    }

    async generateInvoiceCSV(compId: string) {
        return await fetch(`${this.baseUrl}invoice/generate-invoice-csv/${compId}`, this.getFileOptions()).then(this.handlePdfResponse);
    }

    async generateInvoiceFeePDF(compId: string) {
        return await fetch(`${this.baseUrl}invoice/generate-invoice-fee-pdf/${compId}`, this.getFileOptions()).then(this.handlePdfResponse);
    }

    async generateInvoiceFeeCSV(compId: string) {
        return await fetch(`${this.baseUrl}invoice/generate-invoice-fee-csv/${compId}`, this.getFileOptions()).then(this.handlePdfResponse);
    }
    async generateInvoice(id: string) {
        return fetch(`${this.baseUrl}invoice/generate-invoice-registration/${id}`, this.getFileOptions()).then(this.handlePdfResponse);
    }

    async generateInvoiceFee(id: string) {
        return fetch(`${this.baseUrl}invoice/generate-invoice-fee-registration/${id}`, this.getFileOptions()).then(this.handlePdfResponse);
    }

    async sendSuccesEntry(compId: string) {
        return await fetch(`${this.baseUrl}email/send-successfull-entry/${compId}`, this.getOptions()).then(this.handleResponse);
    }

    async sendStartNumbers(compId: string) {
        return await fetch(`${this.baseUrl}email/send-start-numbers/${compId}`, this.getOptions()).then(this.handleResponse);
    }

    async sendCatalog(compId: string) {
        return await fetch(`${this.baseUrl}email/send-catalog-day/${compId}`, this.getOptions()).then(this.handleResponse);
    }

    async sendEvaluations(compId: string) {
        return await fetch(`${this.baseUrl}email/send-evaluations-after-day/${compId}`, this.getOptions()).then(this.handleResponse);
    }

    async sendInvoicesAndExtras(compId: string) {
        return await fetch(`${this.baseUrl}email/send-invoices-and-extra/${compId}`, this.getOptions()).then(this.handleResponse);
    }

    async sendInvoices(compId: string) {
        return await fetch(`${this.baseUrl}email/send-invoices/${compId}`, this.getOptions()).then(this.handleResponse);
    }

    async sendInvoicesAndFees(compId: string) {
        return await fetch(`${this.baseUrl}email/send-invoices-and-fees/${compId}`, this.getOptions()).then(this.handleResponse);
    }

    async sendFeeInvoices(compId: string) {
        return await fetch(`${this.baseUrl}email/send-fee-invoices/${compId}`, this.getOptions()).then(this.handleResponse);
    }
    
    async sendExtras(compId: string) {
        return await fetch(`${this.baseUrl}email/send-extras/${compId}`, this.getOptions()).then(this.handleResponse);
    }

    async contactUser(data: any) {
        return await fetch(`${this.baseUrl}email/contact-user`, this.postOptions(data)).then(this.handleEmailResponse);
    }

    async generateCatalogNumbers(compId: string) {
        return await fetch(`${this.baseUrl}generate/catalog-numbers/${compId}`, this.getOptions()).then(this.handleGenerationResponse);
    }

    async generateJuniorHandlingStartNumbers(id: string) {
        return await fetch(`${this.baseUrl}generate/junior-handling-start-number/${id}`, this.getFileOptions()).then(this.handlePdfResponse);
    }

    async generateAllJuniorHandlingStartNumbers(id: string, type: number) {
        return await fetch(`${this.baseUrl}generate/start-numbers/${type}/${id}`, this.getOptions()).then(this.handleResponse);
    }

    async tvEvents() {
        return await fetch(`${this.baseUrl}tv-info/events`, this.getOptions()).then(this.handleResponse);
    }

    async getShowSchedule(id: string) {
        return await fetch(`${this.baseUrl}tv-info/schedule/show/${id}`, this.getOptions()).then(this.handleResponse);
    }

    async getShowResults(id: string) {
        return await fetch(`${this.baseUrl}tv-info/results/show/${id}`, this.getOptions()).then(this.handleResponse);
    }

    async getBISSchedule(id: string) {
        return await fetch(`${this.baseUrl}tv-info/bis-schedule/show/${id}`, this.getOptions()).then(this.handleResponse);
    }

    private handlePdfResponse = async (response: any) => {
        const contentType = response.headers.get('content-type');
        if (contentType === 'application/pdf' && !response.ok) {
            return response.json().then((data: any) => {
                if (!response.ok) {
                    return Promise.reject(data);
                } 
                return data;
            });
        } else if (contentType === 'application/pdf' || contentType === 'text/csv' || contentType === 'text/plain') {
            response.blob().then((blob: any) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                document.body.appendChild(a);
                let contentDisposition = response.headers.get('content-disposition');
                a.href = url;
                let filename;
                if (contentDisposition) {
                    filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
                } else {
                    filename = url.split("//")[1].split("/")[1];
                }
                a.download = filename;
                a.click();
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
                return;
            });
        } else {
            return response.text().then((text: any) => {
                const data = text && JSON.parse(text);
                if (!response.ok) {
                    return Promise.reject(data);
                }
                return data;
            });
        }
    };


    private handleFileResponse = async (response: any) => {
        if (!response.ok) {
            let text = await response.text();
            return Promise.reject(JSON.parse(text));
        }

        return response.blob().then((blob: any) => {
            return window.URL.createObjectURL(blob);
        })
    }

    private handleEmailResponse = (response: any) => {
        return response.text().then((text: any) => {
            const data = text && JSON.parse(text);

            if (!response.ok) {
                if (response.status === 401) {
                    return;
                }
                return Promise.reject(data);
            }

            return true;
        });
    }
    private handleResponse = (response: any) => {
        return response.text().then((text: any) => {
            const data = text && JSON.parse(text);

            if (!response.ok) {
                if (response.status === 401) {
                    this.store.dispatch(ProfileStore.actionCreators.setUser(null));
                    this.history.push('/');
                    return;
                }

                return Promise.reject(data);
            }

            return data;
        });
    }

    private handleGenerationResponse = (response: any) => {
        return response.text().then((text: any) => {
            const data = text && JSON.parse(text);
            return data;
        });
    }

    private handleImageResponse = (response: any) => {

        return response.url;
    }
}

export default HttpService;
